
body {
  /*************************************************
      ESTILOS COMUM A TODOS OS INPUTS
   ************************************************/

  // estilo de erro ao preencher input
  input.ng-touched, select.ng-touched, ng-autocomplete.ng-touched, textarea.ng-touched, mat-select.ng-touched {
    &.ng-invalid {
      border-color: red !important;
    }
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  
  }
  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  
  }
}
