
h1, h2, h3, h4, h5, h6 {
  color: #676a6c;
}


a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

