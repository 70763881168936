.example-manual-table,
.crud-table,
.dg-table {
  width: 100% !important;

  th {
    background-color: rgb(26, 179, 148);
    border-color: white;
    color: white;
    text-align: center !important;
  }

  td {
    text-align: center !important;
  }
}

.dg-table {

  td,
  th {
    text-align: center !important;
    padding: .5rem 0;
  }

  td {
    border-bottom-width: 1px;
    font-size: 14px;
    border-color: #ced4da;
  }

  position: relative;
  width: auto;
  /*max-width: 100% !important;*/

  .sort-header {
    cursor: pointer;
  }

  .d-none {
    display: none;
  }

  .mat-paginator-range-actions svg {
    fill: gray !important;
  }

  .mat-tooltip {
    /* your own custom styles here */
    /* e.g. */
    color: white !important;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .mat-button-disabled svg {
    opacity: 60%;
  }

}

button {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  border-radius: 0.375rem;
  border-width: 1px;
  padding-top: 0.3em;
}


