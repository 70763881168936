
.card {
  border-width: 4px 0 0;
  border-color: #e7eaec;
  border-right: none;
  border-left: none;
}

.card-footer, .card-header {
  border-width: 4px;
  border-color: #e7eaec;
}
