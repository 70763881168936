$colors-verde-escuro: #11373B;
$colors-verde-medio: #008861FF;
$colors-verde-claro: #518446;

/* Layouts BOOTSTRAP */
$xs-layout: 'only screen and (max-width: 575px)';
$sm-layout: 'only screen and (max-width: 767px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$xl-layout: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$xxl-layout: 'only screen and (min-width: 1400px)';


/* Layouts BOOTSTRAP  MIM (minimo no inicio do grid) */
$min-md-layout: 'only screen and (min-width: 768px)';
$min-lg-layout: 'only screen and (min-width: 992px)';

/* Layouts BOOTSTRAP  MAX (máximo no inicio do grid) */
$max-md-layout: 'only screen and (max-width: 991px)';


// ALTERANDO CORES BASICAR DO BOOTSTAP
// scss-docs-start color-variables
$white: white !default;
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: $colors-verde-claro !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$black: black !default;;

$arrayColors: (
  'white': $white,
  "blue": #0d6efd,
  "indigo": #6610f2,
  "purple": #6f42c1,
  "pink": #d63384,
  "red": #dc3545,
  "orange": #fd7e14,
  "yellow": #ffc107,
  "green-dark": $colors-verde-escuro,
  "green-medio": $colors-verde-medio,
  "green": $colors-verde-claro,
  "teal": #20c997,
  "cyan": #0dcaf0,
  "black": $black,
  "grey": grey
);
